import { Navigate, NavigateFunction, Location } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../context";

interface IProps {
  children: JSX.Element;
}

interface IHistory {
  navigate: NavigateFunction | null;
  location: Location | null;
}

export const history: IHistory = {
  navigate: null,
  location: null,
};

export const PrivateRoute = ({ children }: IProps): JSX.Element => {
  const { token } = useContext(UserContext);

  if (!token) {
    return <Navigate to="/login" state={{ from: history.location }} />;
  }

  return children;
};
