import Box from "@mui/material/Box";
import { Skeleton } from "@mui/material";

export const LoadingOverlay = () => (
  <Box
    sx={{
      height: "max-content",
    }}
  >
    {[...Array(10)].map((_, i) => (
      <Skeleton
        key={i}
        animation="pulse"
        variant="rectangular"
        sx={{ my: 4, mx: 1 }}
      />
    ))}
  </Box>
);
