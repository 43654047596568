import { useContext, useEffect, useMemo, useState } from "react";
import {
  // GridCellParams,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { IChannel, Source, ITheme } from "../../types";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
// import clsx from "clsx";
import Dayjs from "dayjs";
import { Form, Link } from "react-router-dom";
import { UserContext } from "../../context";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import {
  FirstContainerSC,
  GridContainerWrapperSC,
  SecondContainerSC,
} from "./styled";

import './dash.css'
import { Box, Button, Grid, Stack } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const getDate = (date: Date) =>
  Dayjs(date).add(3, "hour").format("HH:mm:ss DD/MM/YYYY");

export const generateLinkToMessage = (inner: string, link: string) => (
  <Link
    to={{
      pathname: `/message/${link}`,
    }}
    style={{ color: "inherit", textDecoration: "none" }}
  >
    {inner}
  </Link>
);



console.log()

export const formatSourceValue = (ch:any) => {
  if (ch.type === Source.RSS) {
    return `rss: ${ch.title}`;
  }
  return `tg: ${ch.title} (${ch.members_count})`;

}

export const formatSourceValueInd = (ch:any) => {
  if (ch.type === Source.RSS) {
    return `${ch.theme}`;
  }
  return `${ch.theme} (${ch.members_count})`;

}

export const Toolbar = (props:any) => {
  
  const {
    setSource,
    source,
    date,
    setDate,
    searchText,
    setSearchText,
    addresses,
    setAddresses,
    themes,
    rssChannels,
    telegramChannels,
    selectedThemes,
    setSelectedThemes,
    themesDict
  } = useContext(UserContext);

  const [selectAll,setSelectAll] = useState(false)
  const [selectAllThemes,setSelectAllThemes] = useState(true)

  let channels = useMemo(
    () => [
      ...rssChannels,
      ...telegramChannels,
    ],
    [rssChannels, telegramChannels]
  );



  const { token } = useContext(UserContext);
   async function Change (event: SelectChangeEvent<typeof addresses>)  {
    const {
      target: { value }
    } = event;
    setAddresses(typeof value === "string" ? value.split(",") : value);

    const chek:any = value[value.length - 1]
    console.log(chek)
    let getObj = channels.find(chan => chan.link === chek) || { id: ""}
    console.log(getObj)

    let getId = getObj.id
    console.log(getId)
    let ids = localStorage.getItem('id')?.split(',') || []
    ids.push(getId)
    window.localStorage.setItem('id', ids.join(','));
    window.dispatchEvent(new Event("storage"));
    console.log(localStorage.getItem('id'))
  };



  const handleCheckAllChange = () => {
    setSelectAll(!selectAll)
    setAddresses(selectAll ? [] : rssChannels.concat(telegramChannels).map((ch)=>ch.link))
  }


  async function ChangeThemes (id:string)  {
    const newval = selectedThemes.includes(id) ? selectedThemes.filter(el=>el!==id) : [...selectedThemes,id]
    setSelectedThemes(newval)
    if(newval.length == themes.keys.length) return
    setSelectAllThemes(false)
    //filter channels
    
  };

  const handleCheckAllChangeThemes = () => {
    setSelectAllThemes(!selectAllThemes)
    setSelectedThemes(selectAllThemes ? [] : themes.map(th=>th.id))
  }

  useEffect(() => {
    localStorage.setItem('count', '100')
  }, [])

  useEffect(() => { 
    setSelectedThemes(selectAllThemes ? themes.map(th=>th.id) : [])
  }, [themes])




  return (
    <Stack direction="row" spacing={2} alignContent={"center"} >
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Тип источника
          </InputLabel>
          <Select
            label="Тип источника"
            value={source}
            onChange={(e) => setSource(e.target.value as Source)}
          >
            <MenuItem value={Source.All}>
              <em>Все</em>
            </MenuItem>
            <MenuItem value={Source.RSS}>RSS</MenuItem>
            <MenuItem value={Source.Telegram}>Телеграм</MenuItem>
          </Select>
        </FormControl>



        <FormControl sx={{ m: 1, width: 400 }}>
          <InputLabel id="demo-multiple-checkbox-label">Источник
          <Checkbox checked={selectAll} onChange={handleCheckAllChange} />
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={addresses}
            onChange={Change}
            input={<OutlinedInput label="Источник" />}
            renderValue={(selected) => channels.filter((ch) => selected.includes(ch.link)).map((ch) => formatSourceValue(ch)).join(",")}
            MenuProps={MenuProps}
          >
            {channels.map((chan, i) => 
              <MenuItem key={i} value={chan.link} id={chan.id}>
                <Checkbox checked={addresses.indexOf(chan.link) > -1} />
                <ListItemText
                  primary={formatSourceValue(chan)}
                  primaryTypographyProps={{ fontSize: "12px" }}
                />
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: 400 }}>
          <InputLabel id="demo-multiple-checkbox-label">Отрасли
          <Checkbox checked={selectAllThemes} onChange={handleCheckAllChangeThemes} />
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedThemes}
            input={<OutlinedInput label="Темы" />}
            renderValue={(selThemes) => themes.filter((th) => selThemes.includes(th.id)).map((th) => th.value).join(",")}
            MenuProps={MenuProps}
          >
            {themes.map( (theme, i) =>
              <MenuItem key={i} value={theme.value} id={theme.id}>
                <Checkbox checked={selectedThemes.indexOf(theme.id) > -1} onChange={()=>ChangeThemes(theme.id)}/>
                <ListItemText
                  primary={theme.value}
                  primaryTypographyProps={{ fontSize: "12px" }}
                />
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormControl sx={{ m: 1, minWidth: 50 }}>
          <DateTimePicker
            label="От"
            value={date.date_from}
            maxDate={Dayjs()}
            inputFormat={"DD/MM/YYYY hh:mm:ss"}
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            ampmInClock={false}
            ampm={false}
            onChange={(date_from) => {
              setDate({
                ...date,
                date_from,
              });
            }}
            renderInput={(params) => (
              <TextField style={{ width: "220px" }} {...params} />
            )}
          />
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 50 }}>
          <DateTimePicker
            label="До"
            value={date.date_to}
            minDate={date.date_from}
            maxDate={Dayjs()}
            inputFormat={"DD/MM/YYYY hh:mm:ss"}
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            ampmInClock={false}
            ampm={false}
            onChange={(date_to) => {
              setDate({
                ...date,
                date_to,
              });
            }}
            renderInput={(params) => (
              <TextField style={{ width: "220px" }} {...params} />
            )}
          />
          </FormControl>
          </LocalizationProvider>
        <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          sx={{ m: 1, minWidth: 200 }}
          label="Поиск"
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
        />
        </FormControl>
        

        <Button variant="contained" onClick={props.onSearchClick}>Поиск</Button>

      </Stack>
  );
};
