import { useState, useEffect, useContext } from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { AddChannelModal } from "../AddChannelModal";
import { IChannel } from "../../types";
import { getValidLink, useChannels } from "../../hooks/useFetch";
import EditIcon from "@mui/icons-material/Edit";
import { UserContext } from "../../context";
import { NoRowsOverlay } from "../NoRowsOverlay";
import { LoadingOverlay } from "../LoadingOverlay";
import { LoadMessagesModal } from "../LoadMessagesModal";

export const Rss = () => {
  const [isOpenedAddModal, setIsOpenedAddModal] = useState(false);
  const [isOpenedLoadMessageModal, setIsOpenedLoadMessageModal] =
    useState(false);
  const [selected, setSelected] = useState<IChannel | undefined>();
  const [loading, setLoading] = useState(true);

  const handleOpenAdd = () => setIsOpenedAddModal(true);
  const handleCloseAdd = () => setIsOpenedAddModal(false);

  const handleOpenLoad = () => setIsOpenedLoadMessageModal(true);
  const handleCloseLoad = () => setIsOpenedLoadMessageModal(false);

  const { rssChannels: rows } = useContext(UserContext);

  const { fetchRssChannels } = useChannels();

  useEffect(() => {
    fetchRssChannels(setLoading);
  }, [fetchRssChannels]);

  const columns: GridColDef[] = [
    {
      field: " ",
      width: 40,
      sortable: false,
      renderCell: (params) => (
        <EditIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelected(rows.find((row) => row.id === params.row.id));
            setIsOpenedAddModal(true);
          }}
        />
      ),
      disableColumnMenu: true,
    },
    {
      field: "id",
      headerName: "ID",
      width: 400,
      disableColumnMenu: true,
    },
    {
      field: "title",
      headerName: "Наименование",
      width: 400,
      disableColumnMenu: true,
    },
    {
      field: "link",
      headerName: "Адрес",
      flex: 1,
      renderCell: (params) => (
        <a
          href={getValidLink(params.row.link)}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          {params.row.link}
        </a>
      ),
      disableColumnMenu: true,
    },
  ];

  const Toolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          onClick={() => {
            setSelected(undefined);
            handleOpenAdd();
          }}
          variant="outlined"
        >
          Добавить
        </Button>
        <Button
          style={{ marginLeft: "5px" }}
          onClick={handleOpenLoad}
          variant="outlined"
        >
          Загрузить канал
        </Button>
        <GridToolbarExport
          csvOptions={{
            delimiter: ";",
            utf8WithBom: true,
          }}
          printOptions={{ disableToolbarButton: true }}
        />
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <AddChannelModal
        defaultState={selected}
        handleClose={handleCloseAdd}
        setLoading={setLoading}
        open={isOpenedAddModal}
        type="rss"
      />
      <LoadMessagesModal
        defaultState={selected}
        handleClose={handleCloseLoad}
        setLoading={setLoading}
        open={isOpenedLoadMessageModal}
        list={rows}
      />
      <Box
        sx={{
          width: "100%",
        }}
      >
        <DataGrid
          style={{ minHeight: "500px" }}
          rows={loading ? [] : rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          components={{ Toolbar, NoRowsOverlay, LoadingOverlay }}
          loading={loading}
          autoHeight
        />
      </Box>
    </>
  );
};
