import { useContext, useState, useEffect } from "react";
import { DataGrid, GridSortModel,GridColDef } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Toolbar ,generateLinkToMessage, getDate} from "./utils";
import { UserContext } from "../../context";
import { IMessage, Source } from "../../types";
import { NoRowsOverlay } from "../NoRowsOverlay";
import { LoadingOverlay } from "../LoadingOverlay";
import Dayjs from "dayjs";
import { useChannels } from "../../hooks/useFetch";
import { useNavigate } from "react-router";
import InfoIcon from "@mui/icons-material/Info";
import { Pages } from "@mui/icons-material";
import { Stack } from "@mui/material";

export const Dashboard = () => {



  const { token, source, date, searchText, addresses, themes, selectedThemes, themesDict} =
    useContext(UserContext);
  const [rows, setRows] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState(true);
  const [schedulerPeriod, setSchedulerPeriod] = useState(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "publish_datetime", sort: "desc" },]);
  const [gridRowCount,setGridRowCount] = useState(100);
  const [page,setPage] = useState(0);
  const pageSize=50;
 
  const navigate = useNavigate();

  const { fetchRssChannels, fetchTelegramChannels, fetchThemes } = useChannels();
  

  const fetchMessages = async (inpage:number) => {
    const { date_from, date_to } = date;
    var params:any={}
    if (sortModel.length>0)
      params={order_by:sortModel[0].field,order_dir:sortModel[0].sort, ...params}

    if (date_from)
      params={...params,date_from:Dayjs(date_from).toISOString()}
    if(date_to) params={date_to:Dayjs(date_to).toISOString(), ...params}
    if(source!==Source.All) params={channel_type:source.toString(), ...params}
    if(selectedThemes.length!==themes.length && selectedThemes.length>0)
      params={themes:selectedThemes.join(","), ...params}
    params={page:inpage,size:pageSize,...params}

    

    try {
      let response = await fetch(
        `/api/message?`+ new URLSearchParams(params).toString(),
        //${dateFrom}${dateTo}${search_text}${channel_type}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          
        }
      );

      response = await response.json();

      //@ts-ignore
      if (response?.details) {
        navigate("/login");
      }

      if (Array.isArray(response)) {
        setRows(
          response
            .map((r: any) => ({
              ...r,
              publish_datetime: new Date(r.publish_datetime),
            }))
            .filter((r: any) =>
              !addresses.length ? true : addresses.includes(r.channel.link)
            )
        );
      }
    } catch (e) {
      navigate("/");
    }
    setLoading(false);
    setGridRowCount((inpage+2)*pageSize)
  };

  const fetchSettings = async () => {
    try {
      let response = await fetch("/api/settings", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      response = await response.json();
      //@ts-ignore
      setSchedulerPeriod(response?.scheduler_period);
    } catch (e) {
      navigate("/");
    }
  };

  const reloadMessages= async () => {
    console.log("reloadMessages p="+page)
    setLoading(true)
    fetchMessages(page)
  }

  useEffect(() => {
    console.log("fetchMessages page,sortModel p="+page)
    setLoading(true)
    fetchMessages(page)
  }, [page, sortModel]);

  useEffect(() => {
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchRssChannels(setLoading);
    fetchTelegramChannels(setLoading);
    fetchThemes();
  }, [fetchRssChannels, fetchTelegramChannels,fetchThemes]);

  var deleteAllMessages = async () => {
    try {
      let response = await fetch("/api/allmessages",{
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },     
      })
      let respdata = await response.json()
    } catch(e) {
      navigate("/")
    }
  }

  useEffect(() => {
    localStorage.removeItem('id')
    localStorage.removeItem('theme')
  }, [])


const columns: GridColDef[] = [
  {
    headerName: "ID",
    field: "id",
    width: 300,
    renderCell: (params) => generateLinkToMessage(params.row.id, params.row.id),
    disableColumnMenu: true,
    sortable: false,
  },
  {
    headerName: "Тема",
    field: "theme",
    width: 300,
    renderCell: (params) => themesDict[params.row.channel.theme],
    disableColumnMenu: true,
    sortable: false,
  },
  {
    headerName: "Дата",
    field: "publish_datetime",
    width: 200,
    description: "ЧЧ:ММ:СС ДД/ММ/ГГГ",
    disableColumnMenu: true,
    renderCell: (params) => getDate(params.row.publish_datetime),
    sortable: true,
  },
  {
    headerName: "Сообщение",
    description: "Первые 100 символов",
    field: "text",
    flex: 1.5,
    renderCell: (params) =>
      generateLinkToMessage(params.row.text, params.row.id),
    disableColumnMenu: true,
    sortable: false,

    // uncomment to see colored messages
    // cellClassName: (params: GridCellParams) => {
    //   const mood = params.row.mood;

    //   return clsx("message_preview", {
    //     negative: mood < 0,
    //     positive: mood > 0,
    //     neitral: mood === 0,
    //   });
    // },
  },
  {
    headerName: "Источник",
    field: "channel.title",
    description: "Адрес канала",
    flex: 0.5,
    renderCell: (params) => params.row.channel.title,
    disableColumnMenu: true,
    valueGetter: (params) => params.row.channel.title,
    sortComparator: (v1, v2) => v1?.localeCompare(v2),
    sortable: false,

  },
  {
    headerName: "Тип источника",
    field: "source",
    description: "RSS / ТГ-канал",
    flex: 0.3,
    renderCell: (params) =>
      params.row.channel.type === Source.RSS ? "RSS" : "Телеграм",
    disableColumnMenu: true,
    sortable: false,

  },
  {
    headerName: "Подписчики",
    field: "members_count",
    flex: 0.3,
    renderCell: (params) =>
      params.row.channel.type === Source.RSS
        ? ""
        : params.row.channel.members_count,
    disableColumnMenu: true,
    sortable: false,
  },
];


  return (
    <Box
      sx={{
        width: "100%",
        // uncomment to see colored messages
        //   "& .negative": {
        //     color: "red",
        //   },
        //   "& .positive": {
        //     color: "green",
        //   },
      }}
    >
      {Boolean(schedulerPeriod) && (
        <p
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <InfoIcon color="info" /> Период опроса каналов: {schedulerPeriod}{" "}
          минут
          <Button variant="outlined" onClick={deleteAllMessages}>Удалить все сообщения</Button>
        </p>
      )}

      <Toolbar onSearchClick={reloadMessages} />
      <DataGrid
        rows={loading ? [] : rows}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        pagination
        paginationMode="server"
        components={{

          NoRowsOverlay,
          LoadingOverlay,
        }}
        style={{ minHeight: "500px" }}
        autoHeight
        loading={loading}
        initialState={{
          sorting: {
            sortModel: [{ field: "publish_datetime", sort: "desc" }],
          },
        }}
        rowCount={gridRowCount}
        onPageChange={(newpage) => {console.log("onPageChange  p="+page+ " np="+newpage);setPage(newpage)}}
        onSortModelChange = {(newSortModel) => setSortModel(newSortModel)}
      />
    
    </Box>
  );
};
