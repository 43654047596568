import { useContext, useCallback } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { UserContext } from "../../context";
import { Nav } from "./styled";
import LogoutIcon from "@mui/icons-material/Logout";

interface INavLinkProps {
  to: string;
  children: string;
}

export default function Navbar() {
  const { token, setToken } = useContext(UserContext);

  const logoutHandler = useCallback(() => {
    setToken(null);
    localStorage.removeItem("token");
  }, [setToken]);

  return token ? (
    <Nav>
      <ul>
        <NavLink to="/">Главная</NavLink>
        <NavLink to="/rss">RSS</NavLink>
        <NavLink to="/telegram">Телеграм</NavLink>
        <NavLink to="/datafiles">Файлы данных</NavLink>
      </ul>
      <LogoutIcon onClick={logoutHandler} />
    </Nav>
  ) : null;
}

const NavLink = ({ to, children }: INavLinkProps) => {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li>
      <Link
        style={{
          minWidth: "150px",
          borderRadius: "5px",
          backgroundColor: isActive ? "#5a656e" : "#8194a3",
        }}
        to={to}
      >
        {children}
      </Link>
    </li>
  );
};
