import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { UserContext } from "../../context";
import CircularProgress from "@mui/material/CircularProgress";
import { IMessage, Source } from "../../types";
import { NoRowsOverlay } from "../NoRowsOverlay";
import Container from "@mui/material/Container";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import { getDate } from "../Dashboard/utils";

interface IMessageView {
  message: IMessage | null;
}

export const Message = () => {
  const { messageId } = useParams();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState<IMessage | null>(null);

  const { token } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (messageId) {
      const fetchMessage = async () => {
        try {
          const response = await fetch(`/api/message/${messageId}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });

          const data = await response.json();
          setMessage(data);
          setLoading(false);
        } catch (e) {
          setMessage(null);
          setLoading(false);
          navigate("/");
        }
      };

      fetchMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageId, token]);

  return loading ? (
    <CircularProgress disableShrink />
  ) : (
    <MessageView message={message} />
  );
};

const MessageView = ({ message }: IMessageView) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const sxLabel = { width: "33%", flexShrink: 0 };
  const sxDesc = { color: "text.secondary" };
  const sxInnerDesc = { color: "text.secondary", marginLeft: "-25px" };
  const sxBox = { display: "flex" };

  return message ? (
    <Container style={{ fontFamily: "sans-serif" }} maxWidth="md">
      {message.channel && (
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={sxLabel}>Источник</Typography>
            <Typography sx={sxDesc}>{message.channel.title}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ margin: "0 50px" }}>
            <Box sx={sxBox}>
              <Typography sx={sxLabel}>Тип</Typography>
              <Typography sx={sxInnerDesc}>
                {message.channel.type === Source.Telegram ? "Телеграм" : "RSS"}
              </Typography>
            </Box>
            <Box sx={sxBox}>
              <Typography sx={sxLabel}>Наименование</Typography>
              <Typography sx={sxInnerDesc}>{message.channel.title}</Typography>
            </Box>
            <Box sx={sxBox}>
              <Typography sx={sxLabel}>Ссылка</Typography>
              <Typography sx={sxInnerDesc}>
                <a href={message.channel.link} target="_blank" rel="noreferrer">
                  {message.channel.link}
                </a>
              </Typography>
            </Box>
            <Box sx={sxBox}>
              <Typography sx={sxLabel}>ID</Typography>
              <Typography sx={sxInnerDesc}>{message.channel.id}</Typography>
            </Box>
            {message.channel.members_count && (
              <Box sx={sxBox}>
                <Typography sx={sxLabel}>Подписчики</Typography>
                <Typography sx={sxInnerDesc}>
                  {message.channel.members_count}
                </Typography>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {message.extra && (
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={sxLabel}>Автор</Typography>
            <Typography sx={sxDesc}>{message.extra.author}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ margin: "0 50px" }}>
            <Box sx={sxBox}>
              <Typography sx={sxLabel}>Автор</Typography>
              <Typography sx={sxInnerDesc}>{message.extra.author}</Typography>
            </Box>
            <Box sx={sxBox}>
              <Typography sx={sxLabel}>Наименование</Typography>
              <Typography sx={sxInnerDesc}>{message.extra.title}</Typography>
            </Box>
            <Box sx={sxBox}>
              <Typography sx={sxLabel}>Ссылка</Typography>
              <Typography sx={sxInnerDesc}>
                <a href={message.extra.link} target="_blank" rel="noreferrer">
                  {message.extra.link}
                </a>
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion expanded={true}>
        <AccordionDetails>
          <Box style={{ marginBottom: "20px" }} sx={sxBox}>
            <Typography sx={sxLabel}>Дата публикации</Typography>
            <Typography>
              {getDate(new Date(message.publish_datetime))}
            </Typography>
          </Box>
          <Box sx={sxBox}>
            <Typography sx={sxLabel}>Сообщение</Typography>
            <Typography>{message.text}</Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Container>
  ) : (
    <NoRowsOverlay message="Сообщение не найдено" />
  );
};
