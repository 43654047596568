import { Alert, Button, CircularProgress, Container, Grid, Link, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context";
import './datafiles.css'

export const Datafiles = () => {
    const [datafiles,setDatafiles] = useState([]);
    const [alertNewFile,setAlertNewFile] = useState(false);
    const [alertClear,setAlertClear] = useState(false);
    const [alertClearTasks,setAlertClearTasks] = React.useState(false);
    const [pullTasks,setPullTasks] = React.useState([]);
    const [betterTasks, setBetterTasks] = useState([]);

    // const [data, setData] = useState();
    

    const { token, setRssChannels, setTelegramChannels } = useContext(UserContext);

    function generateNewFile() {
        fetch("/api/datafile",{method:"POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },                
    }).then((resp)=>resp.json())
        .then(data => {
            console.log(data)
            setAlertNewFile(true)
            setTimeout(()=> setAlertNewFile(false),3000)
        })
    }
    function clearAll() {
        fetch("/api/datafiles",{method:"DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },                

    }).then((resp)=>resp.json()).then(data => {
            console.log(data)
            setAlertClear(true)
            setTimeout(()=> setAlertClear(false),3000)
        
        }).catch(err => console.log(err));
    }

    function clearFinishedTasks() {
        fetch("/api/tasks",{method:"DELETE",headers: {
            Authorization: `Bearer ${token}`,
           "Content-Type": "application/json",
        },}).then((resp)=>resp.json()).then(data=> {
            console.log(data)
            setAlertClearTasks(true);
            setTimeout(()=> setAlertClearTasks(false),3000)
        })
    }

    function fetchDatafiles() {
        fetch("/api/datafiles",{
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },                
        })
        .then(res => {
            if(res.ok) {
                return res.json();
            }
            throw new Error(`Network response error : ${res.statusText}`);
        })
        .then(data => setDatafiles(data))
        .catch(err => console.log(err));
    
    }

    function fetchPullJobs() {
        fetch("/api/tasks",{method: "GET",
           headers: {
               Authorization: `Bearer ${token}`,
               "Content-Type": "application/json",
             },                
            })
            .then(res => {
                if(res.ok) 
                    return res.json() 
                else throw new Error(`Network response error : ${res.statusText}`)})
            .then(data => setPullTasks(data))
            .catch(err=>console.log(err))
    }

    function subscribeEvents() {
        const es = new window.EventSource("/api/datafiles/events",{withCredentials:true})
        es.onopen = ()=>{console.log("Соединение установлено")}
        es.onerror = (e) => {console.log("Error:"+e)}
        es.onmessage = (e) => {
            const data = JSON.parse(e.data)
            /*  Переводим статусы на русский язык  */
            data.tasks.map((el:any) => {
                if (el.status == "processing") {
                    el.status = "В работе"
                } 
                if (el.status == "done") {
                    el.status = "Завершено"
                } 
            })
            data.datafiles.map((el:any) => {
                if(el.status == "creating") {
                    el.status = "Создается"
                }
            })
            /*      END       */
            setDatafiles(data.datafiles)
            setPullTasks(data.tasks)
        }
    }

    useEffect(() => {
            subscribeEvents()
        },[])


    return (
        <Container maxWidth="lg">
            <Stack spacing={2} direction="row">
            <Container maxWidth="sm">
                <Stack spacing={2}>
                    <Container maxWidth="sm">
                        <h1>Файлы выгрузки</h1>
                    </Container>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <h3>Операции</h3>
                        </Grid>
                        <Grid item xs={8}>
                            <Stack direction="row" spacing={2}>
                                <Button color="primary" variant="contained" onClick={()=> generateNewFile()} >новый файл</Button>
                                <Button color="error" variant="outlined" onClick={()=> clearAll()} >Очистить все</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                    { alertNewFile && <Alert severity="success">Начата подготовка файла</Alert>}
                    { alertClear && <Alert severity="error">Файлы очищены</Alert>}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Дата</TableCell>
                                <TableCell>Записи</TableCell>
                                <TableCell>Состояние</TableCell>
                                <TableCell>Скачать</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {datafiles.map((df:any)=> {
                                    return (
                                        <TableRow key={df.id}>
                                            <TableCell>{new Date(df.timestamp*1000).toLocaleString()}</TableCell>
                                            <TableCell>{df.rows}</TableCell>
                                            <TableCell>{df.status}</TableCell>
                                            <TableCell>{
                                            (df.status=="Создается") ? (<CircularProgress/>) : (
                                                <a onClick={()=>{window.open("/api/datafile/download/"+df.id,"blank_")}} href="#">Скачать</a>)
                                            }</TableCell>
                                        </TableRow>
                                    )
                                    })}
                            </TableBody>
                    </Table>
                </Stack>
            </Container>
            <Container maxWidth="sm">
            <Stack spacing={2}>
                <Container maxWidth="sm">
                    <h1>Задачи загрузки файлов</h1>
                </Container>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <h3>Операции</h3>
                    </Grid>
                    <Grid item xs={8}>
                        <Stack direction="row" spacing={2}>
                            <Button color="error" variant="outlined" onClick={()=> clearFinishedTasks()} >Очистить завершенные</Button>
                        </Stack>
                    </Grid>
                </Grid>
                { alertClearTasks && <Alert severity="error">Завершенные задачи удаляются</Alert>}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            {/* <TableCell>Дата</TableCell> */}
                            <TableCell>Записи</TableCell>
                            <TableCell>Состояние</TableCell>
                            <TableCell>Название канала</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {pullTasks.map((ts:any)=> {
                                return (
                                    <TableRow key={ts.id}>
                                        <TableCell>{ts.id}</TableCell>
                                        <TableCell>{ts.details.messages_count}</TableCell>
                                        <TableCell className={ts.status == "В работе" ? "status--work" : "status--done"}>{ts.status}</TableCell>
                                        <TableCell>{ts.details.last_chan}</TableCell>
                                    </TableRow>
                                )
                                })}
                        </TableBody>
                </Table>
            </Stack>
        </Container>
        </Stack>
    </Container>
  );

}