import styled from "styled-components";

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  svg {
    position: absolute;
    right: 40px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  ul {
    justify-content: center;
    list-style: none;
    font-size: 23px;
    display: flex;
    gap: 50px;
    li {
      a {
        background-color: #8194a3;
        padding: 10px 30px;
        text-decoration: none;
        color: white;
        :hover {
          opacity: 0.8;
        }
      }
    }
  }
`;
