import { useState, Dispatch, SetStateAction, useContext, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { IChannel } from "../types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker/DateTimePicker";
import { Alert, Checkbox, FormHelperText, ListItemText, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { UserContext } from "../context";
import Snackbar from "@mui/material/Snackbar";
import { formatSourceValue,MenuProps } from "./Dashboard/utils"
import { BorderColor, Padding } from "@mui/icons-material";
 
interface IProps {
  list: IChannel[];
  open: boolean;
  handleClose: () => void;
  contentText?: string;
  defaultState?: IChannel;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export const LoadMessagesModal = (props: IProps) => {
  const { open, handleClose, contentText, defaultState, list } = props;

  const [listChannels, setListChannels] = useState(list.map((ch)=>ch))

  
  useEffect(() => {
      setListChannels(list)
  }, [list]);

  const { token } = useContext(UserContext);

  const [channels, setChannels] = useState<string[]>([]);
  const [date, setDate] = useState<Dayjs | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [selectAll,setSelectAll] = useState(false)
  const [textAll,setTextAll] = useState("Выбрать всё")

  const now = new Date()
  const today = now.toLocaleDateString()
  const hours = now.getHours()
  const minutes =  now.getMinutes()


  const changeHandler = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setChannels(typeof value === "string" ? value.split(",") : value);
  };

  const onSave = () => {
    const save = async () => {
      try {
        await fetch(`/api/channels/pull`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            channels: channels,
            before_date: date,
          }),
        });
      } catch (e) {}
    };

    if (channels.length==0) {
      return;
    }

    save();
    setSubmitted(true);
    setIsAlertOpen(true);
    handleClose();

    if (submitted) {
      setDate(null);
      setSelectAll(false)
      setChannels([]);
    }
  };

  const handleCheckAllChange = () => {
    setSelectAll(!selectAll)
    setChannels(selectAll ? [] : list.map((ch)=>ch.id))
    if (textAll === "Выбрать всё") {
      setTextAll("Очистить всё")
    } else {
      setTextAll("Выбрать всё")
    }
  }

  const [subsequence, setSubsequence] = useState(0)
  const [filterAl, setFilterAl] = useState('А - Я')

  const sortAlphabet = () => {
    if (subsequence === 0) {
      setSubsequence(1)
      setFilterAl("Я - А")
      const listCopy = [...list]
      listCopy.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
    setListChannels(listCopy)
  } else if (subsequence === 1) {
    setFilterAl("По умолчанию")
    const listCopy = [...list]
    const reverseCopy = listCopy.reverse()
    setListChannels(reverseCopy)
    console.log(listChannels)
    setSubsequence(2)
  } else if (subsequence === 2) {
    setFilterAl("А - Я")
    setListChannels(list)
    setSubsequence(0)
  }
  }

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Загрузить канал</DialogTitle>
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
          <DialogContent>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", margin:"0 0 .3em 0"}}>
            <p style={{margin:"1em 0 .2em 0", fontSize: "1em", fontFamily:"sans-serif"}}>Канал</p>
            <div style={{display: "flex", flexDirection: "row"}}> 
              <button onClick={sortAlphabet} style={{padding:".2em .5em", fontSize: ".9em", fontFamily:"sans-serif", backgroundColor:"#fff", border:"1px solid #CECECE", borderRadius:".3em", cursor:"pointer", margin: "0 .3em"}}>{filterAl}</button>
              <button onClick={handleCheckAllChange} style={{padding:".2em .5em", fontSize: ".9em", fontFamily:"sans-serif", backgroundColor:"#fff", border:"1px solid #CECECE", borderRadius:".3em", cursor:"pointer"}}>{textAll}</button>
            </div>
            </div>
            <FormControl fullWidth>
              <InputLabel>Выберите канал
              </InputLabel>
              <Select
                required
                multiple
                value={channels}
                label="channel"
                onChange={changeHandler}
                renderValue={(selected) => list.filter((ch) => selected.includes(ch.id)).map((ch) => formatSourceValue(ch))}
                MenuProps ={MenuProps}
              >
            {listChannels.map((chan, i) => 
              <MenuItem key={i} value={chan.id}>
                <Checkbox checked={channels.indexOf(chan.id) > -1} />
                <ListItemText
                  primary={formatSourceValue(chan)}
                  primaryTypographyProps={{ fontSize: "12px" }}
                />
              </MenuItem>
            )}
              </Select>
            </FormControl>
            <p style={{margin:"1em 0 0 0", fontSize: "1em", fontFamily:"sans-serif"}}>От</p>
            <FormControl fullWidth style={{ marginTop: "10px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  value={date}
                  maxDate={dayjs()}
                  inputFormat={"DD/MM/YYYY HH:mm"}
                  views={[
                    "year",
                    "month",
                    "day",
                    "hours",
                    "minutes",
                    "seconds",
                  ]}
                  ampmInClock={false}
                  ampm={false}
                  onChange={setDate}
                  renderInput={(params) => <TextField {...params} />}
                />
                <p style={{margin:"1em 0 0 0", fontSize: "1em", fontFamily:"sans-serif"}}>До</p>
                <div style={{padding: "1.1em .8em", margin:".55em 0", fontSize: "1em", fontFamily:"sans-serif", border:"1px solid #CECECE", borderRadius:".3em", fontStyle:"italic"}}>{today} {hours}:{minutes}</div>
              </LocalizationProvider>
            </FormControl>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              setDate(null);
              setChannels([]);
              setSubmitted(false);
            }}
          >
            Отмена
          </Button>
          <Button type="submit" color="success" onClick={onSave}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isAlertOpen}
      >
        <Alert
          onClose={() => setIsAlertOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Загрузка удачно началась!
        </Alert>
      </Snackbar>
    </>
  );
};
